.container {
  padding: 10px;
  background-color: white;
  height: 100vh;
}
.footer {
  position: absolute;
  bottom: 10px;
  right: 15px;
}
